<template>
  <div class="drag-audio">
    <base-drag-input
      v-if="!preview"
      class="drag-audio__input"
      accept="audio/*"
      @uploaded="uploadAudio"
    >
      <div class="d-flex justify-space-around align-center">
        <v-img class="base-drag-input__icon mb-0" src="/img/materials/play-icon.svg" />
        <div style="width: 50%" class="d-flex flex-column align-center">
          <p class="base-drag-input__title mb-1">
            Arrastrar archivo
          </p>
          <p class="base-drag-input__subtitle mb-0 text-center">
            Formatos de audio permitido (mp3,wav, wmv, wma)
          </p>
        </div>
      </div>
    </base-drag-input>
    <div v-else class="full-width" style="position: relative;">
      <audio id="video" class="mt-5" :src="preview" :autoplay="autoplay" onerror="failed(event)" controls="controls"
             preload="none"
      />
      <v-btn
        class="remove-btn ma-2"
        fab
        dark
        height="35px"
        width="35px"
        color="error"
        @click="removeAudio"
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import BaseDragInput from '@/components/inputs/BaseDragInput'

export default {
  name: 'DragAudioInput',
  components: {
    BaseDragInput
  },
  props: {
    file: [File, String],
    previewUrl: String,
    autoplay: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      preview: this.previewUrl
    }
  },
  watch: {
    file (newFile) {
      if (newFile) {
        this.setPreviewFile(newFile)
      } else {
        this.preview = null
      }
    }
  },
  methods: {
    uploadAudio (e) {
      this.$emit('loadedAudio', e)
    },
    removeAudio () {
      this.preview = null
      this.$emit('clearAudio')
    },
    setPreviewFile (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        this.preview = e.target.result
      }
    }
  }
}
</script>

<style scoped lang="scss">

.drag-audio {
  height: 115px;

  &__input {
    height: 115px;
  }
}

.remove-btn {
  position: absolute;
  right: -20px;
  top: 0px;
}

</style>
