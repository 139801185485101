import { render, staticRenderFns } from "./AddBlock.vue?vue&type=template&id=7f6bae28&scoped=true"
import script from "./AddBlock.vue?vue&type=script&lang=js"
export * from "./AddBlock.vue?vue&type=script&lang=js"
import style0 from "./AddBlock.vue?vue&type=style&index=0&id=7f6bae28&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f6bae28",
  null
  
)

export default component.exports