<template>
  <v-menu offset-y v-bind="$attrs">
    <template v-slot:activator="{ on, attrs, value }">
      <div class="d-flex flex-column mx-1" :class="{'error--text': errorMessage}" style="position: relative;">
        <label for="" class="label">{{ label }}</label>
        <v-card text v-bind="attrs"
                class="activator mb-2"
                :min-width="activatorWidth || '130px'"
                height="52px"
                v-on="on"
        >
          <div class="activator__container px-2">
            <slot name="show-item">
              {{ local ? local[itemText] : noItemText }}
            </slot>
          </div>
          <div class="activator__icon" :class="{'activator__icon--active': value}">
            <v-icon size="26px" :color="value? '#ffffff': '#C4C4C4'">
              {{ 'mdi-chevron-down' }}
            </v-icon>
          </div>
        </v-card>
        <v-slide-y-transition>
          <span v-show="errorMessage" class="mx-3 mb-0 error-message" transition="fade">
            {{ errorMessage }}
          </span>
        </v-slide-y-transition>
      </div>
    </template>
    <slot name="content" :items="items">
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectItem(item)"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </slot>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseMenu',
  props: {
    items: Array,
    noItemText: String,
    label: String,
    activatorWidth: String,
    value: [Object, String],
    itemText: String,
    itemValue: String,
    returnObject: Boolean,
    errorMessage: String
  },
  data () {
    return {}
  },
  computed: {
    local () {
      return this.value
    }
  },
  methods: {
    selectItem (item) {
      this.returnObject ? this.$emit('input', item) : this.$emit('input', item[this.itemValue])
    }
  }
}
</script>

<style scoped lang="scss">

.activator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #929292;

  &__container {
    border-radius: 10px 0px 0px 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #243674;
    font-weight: 600;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 54px;
    height: 100%;
    border-radius: 0px 10px 10px 0px;

    &--active {
      background-color: #0091CD;
      border-radius: 0px 10px 10px 0px !important;
    }
  }
}

.label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  color: #C4C4C4;
  margin-bottom: 4px;
}

.error-message {
  font-size: 12px;
  position: absolute;
  bottom: -12px;
}
</style>
