<template>
  <v-card class="block-input d-flex flex-column justify-center align-center" height="345px">
    <p class="block-input__title mb-10">
      Añadir:
    </p>
    <div class="d-flex">
      <v-btn v-for="(type, index) in contentsTypes" :key="index" text class="content-type-item mx-5" @click="$emit('blockTypeSelected', type.value)">
        <v-img contain class="mb-2" max-width="20px" max-height="20px" :src="type.icon" />
        <p class="content-type-item__name">
          {{ type.name }}
        </p>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'BlockInput',
  data () {
    return {
      contentsTypes: [
        { name: 'Texto', value: 'text', icon: '/img/materials/text-icon.svg' },
        { name: 'Imagen', value: 'image', icon: '/img/materials/image-icon.svg' },
        { name: 'Video', value: 'video', icon: '/img/materials/record-icon.svg' },
        { name: 'Archivo', value: 'file', icon: '/img/materials/file-icon.svg' },
        { name: 'Botón', value: 'button', icon: '/img/materials/link-icon.svg' },
        { name: 'Audio', value: 'audio', icon: '/img/materials/audio-icon.svg' }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.block-input {
  border: 1px solid #E2E9E6;
  border-radius: 15px;

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #4C4C4C;
  }
}

.content-type-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: none;
  letter-spacing: 0;

  &__name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
  }
}

.content-type-item::v-deep.v-btn{
  height: 75px;
}

.content-type-item::v-deep .v-btn__content{
  flex-direction: column;
  justify-content: center;
}

</style>
