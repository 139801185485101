<template>
  <base-block @delete="$emit('delete')">
    <div class="mx-auto" style="max-width: 100%">
      <drag-image-input :image="block.file" :preview-url="block.fileUrl" @loadedImage="loadedImage" @clearImage="clearFile" />
      <v-slide-y-transition>
        <span v-show="errorMessage" class="mx-3 mb-0 error--text" transition="fade">
          {{ errorMessage }}
        </span>
      </v-slide-y-transition>
    </div>
  </base-block>
</template>

<script>
import DragImageInput from '@/components/inputs/DragImageInput'
import BaseBlock from '@/components/material/blocks/BaseBlock'

export default {
  name: 'ImageBlock',
  components: {
    DragImageInput,
    BaseBlock
  },
  props: ['block'],
  data () {
    return {
      errorMessage: ''
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.block, [key]: value })
    },
    loadedImage ($event) {
      this.errorMessage = ''
      this.update('file', $event)
    },
    clearFile () {
      this.$emit('input', { ...this.block, file: null, fileUrl: null })
    },
    validate () {
      let validate = true
      if (!this.block.fileUrl && !this.block.file) {
        this.errorMessage = 'Este campo requerido'
        validate = false
      }
      return validate
    }
  }
}
</script>

<style scoped lang="scss">

.error--text {
  font-size: 12px;
}

</style>
