<template>
  <base-block @delete="$emit('delete')">
    <div class="mt-5">
      <rich-text v-model="block.content" @input="errorMessage=''" />
      <v-slide-y-transition>
        <span v-show="errorMessage" class="mx-3 mb-0 error--text" transition="fade">
          {{ errorMessage }}
        </span>
      </v-slide-y-transition>
    </div>
  </base-block>
</template>

<script>
import BaseBlock from '@/components/material/blocks/BaseBlock'
import RichText from '@/components/inputs/RichText'

export default {
  name: 'TextBlock',
  components: {
    RichText,
    BaseBlock
  },
  inheritAttrs: false,
  props: ['block'],
  data () {
    return {
      errorMessage: ''
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.block, [key]: value })
    },
    validate () {
      let validate = true
      if (!this.block.content) {
        this.errorMessage = 'Este campo requerido'
        validate = false
      }
      return validate
    }
  }
}
</script>

<style scoped lang="scss">

.error--text {
  font-size: 12px;
}

</style>
