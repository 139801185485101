<template>
  <div class="drag-image">
    <base-drag-input v-if="!preview" @uploaded="uploadImage" />
    <div v-else class="full-width d-flex justify-center align-center">
      <v-img :src="preview" class="mt-5 mx-auto text-right">
        <v-btn
          class="ma-2"
          fab
          dark
          height="35px"
          width="35px"
          color="error"
          @click="removeImage"
        >
          <v-icon dark>
            mdi-close
          </v-icon>
        </v-btn>
      </v-img>
    </div>
  </div>
</template>

<script>
import BaseDragInput from '@/components/inputs/BaseDragInput'

export default {
  name: 'DragImageInput',
  components: {
    BaseDragInput
  },
  props: ['image', 'previewUrl'],
  data () {
    return {
      preview: this.previewUrl
    }
  },
  watch: {
    previewUrl (value) {
      this.preview = value
    }
  },
  methods: {
    uploadImage (e) {
      this.setPreviewImage(e)
      this.$emit('loadedImage', e)
    },
    removeImage () {
      this.preview = null
      this.$emit('clearImage')
    },
    setPreviewImage (image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.preview = e.target.result
      }
    }
  }
}
</script>

<style scoped lang="scss">
.drag-image {
  max-width: 100%;
}
</style>
