<template>
  <base-block @delete="$emit('delete')">
    <div class="mx-auto">
      <drag-video-input :file="block.file" :preview-url="block.fileUrl" :autoplay="!isEditing || !!block.file" @loadedVideo="loadedVideo" @clearFile="clearFile" />
      <v-slide-y-transition>
        <span v-show="errorMessage" class="mx-3 mb-0 error--text" transition="fade">
          {{ errorMessage }}
        </span>
      </v-slide-y-transition>
    </div>
  </base-block>
</template>

<script>
import BaseBlock from '@/components/material/blocks/BaseBlock'
import DragVideoInput from '@/components/inputs/DragVideoInput'

export default {
  name: 'VideoBlock',
  components: {
    BaseBlock,
    DragVideoInput
  },
  props: ['block', 'isEditing'],
  data () {
    return {
      errorMessage: ''
    }
  },
  methods: {
    loadedVideo ($event) {
      this.errorMessage = ''
      this.update('file', $event)
    },
    update (key, value) {
      this.$emit('input', { ...this.block, [key]: value })
    },
    clearFile () {
      this.$emit('input', { ...this.block, file: null, fileUrl: null })
    },
    validate () {
      let validate = true
      if (!this.block.file && !this.block.fileUrl) {
        this.errorMessage = 'Este campo requerido'
        validate = false
      }
      return validate
    }
  }
}
</script>

<style scoped lang="scss">
.error--text {
  font-size: 12px;
}
</style>
