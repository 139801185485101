<template>
  <div class="">
    <label for="" class="label">{{ label }}</label>
    <v-textarea v-bind="$attrs" plain outlined class="" v-on="$listeners">
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </v-textarea>
  </div>
</template>

<script>
export default {
  name: 'BaseTextArea',
  inheritAttrs: false,
  props: {
    label: String
  }
}
</script>

<style scoped lang="scss">
.label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  color: #C4C4C4;
}

.v-textarea ::v-deep textarea {
  color: #243674;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.05em;
}

.v-textarea--filled > .v-input__control > .v-input__slot, .v-textarea--filled.v-input--dense.v-textarea--outlined > .v-input__control > .v-input__slot, .v-textarea--filled.v-input--dense.v-textarea--outlined.v-textarea--filled > .v-input__control > .v-input__slot, .v-textarea--full-width.v-input--dense.v-textarea--single-line > .v-input__control > .v-input__slot, .v-textarea--full-width.v-input--dense.v-textarea--outlined > .v-input__control > .v-input__slot, .v-textarea--full-width.v-input--dense.v-textarea--outlined.v-textarea--filled > .v-input__control > .v-input__slot, .v-textarea--outlined.v-input--dense.v-textarea--single-line > .v-input__control > .v-input__slot, .v-textarea--outlined.v-input--dense.v-textarea--outlined > .v-input__control > .v-input__slot, .v-textarea--outlined.v-input--dense.v-textarea--outlined.v-textarea--filled > .v-input__control > .v-input__slot {
  min-height: 36px;
}

.v-textarea ::v-deep .v-input__prepend-inner {
  margin-top: 14px;
}

.v-textarea ::v-deep .v-input__slot fieldset {
  border: 0.5px solid #929292 !important;
  border-radius: 10px;
}

.v-textarea ::v-deep .v-textarea__details {
  margin-bottom: 2px;
  padding: 0 8px;
}

.v-textarea ::v-deep .v-input__append-inner {
  margin-top: 13px;
}

</style>
