<template>
  <div class="drag-video">
    <base-drag-input
      v-if="!preview"
      icon="/img/materials/record-input-icon.svg"
      subtitle="Formatos de video permitido (mp4,mov,flv, wmv)"
      accept="video/*"
      @uploaded="uploadVideo"
    />
    <div v-else class="full-width d-flex justify-center align-center" style="position: relative;">
      <video id="video" class="mt-5" :src="preview" :autoplay="autoplay" onerror="failed(event)" controls="controls"
             preload="none" style="max-width: 90%;"
      />
      <v-btn
        class="remove-btn ma-2"
        fab
        dark
        height="35px"
        width="35px"
        color="error"
        @click="removeVideo"
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import BaseDragInput from '@/components/inputs/BaseDragInput'

export default {
  name: 'DragVideoInput',
  components: {
    BaseDragInput
  },
  props: {
    previewUrl: String,
    autoplay: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      preview: this.previewUrl
    }
  },
  methods: {
    uploadVideo (e) {
      this.setPreviewVideo(e)
      this.$emit('loadedVideo', e)
    },
    removeVideo () {
      this.preview = null
      this.$emit('clearFile')
    },
    setPreviewVideo (image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.preview = e.target.result
      }
    }
  }
}
</script>

<style scoped lang="scss">
.remove-btn {
  position: absolute;
  right: 0;
  top: 20px;
}
</style>
