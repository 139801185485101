<template>
  <div class="base-drag-input"
       v-bind="$attrs"
       onmouseover="this.style.background='rgba(0,0,0,0.25)';"
       onmouseout="this.style.background='#D9D9D9';"
       @dragover="dragover"
       @dragleave="dragleave"
       @drop="drop"
       v-on="$listeners"
       @click="openInputFile"
  >
    <slot>
      <v-img contain class="base-drag-input__icon mb-6"
             :src="icon"
      />
      <p class="base-drag-input__title">
        {{ title }}
      </p>
      <p class="base-drag-input__subtitle">
        {{ subtitle }}
      </p>
    </slot>
    <slot name="input">
      <input v-show="false" ref="uploadInput" type="file" :accept="accept" @change="onChangeInputFile">
    </slot>
  </div>
</template>

<script>
export default {
  name: 'DragFile',
  components: {},

  props: {
    accept: {
      type: String,
      default () {
        return 'image/jpeg'
      }
    },
    icon: {
      type: String,
      default () {
        return '/img/materials/drag-image.svg'
      }
    },
    title: {
      type: String,
      default () {
        return 'Arrastrar archivo'
      }
    },
    subtitle: {
      type: String,
      default () {
        return 'Formatos de imagen permitido (jpg,png,svg, targa, tiff)'
      }
    }
  },
  methods: {
    dragover (event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('base-drag-input--drag')) {
        event.currentTarget.classList.add('base-drag-input--drag')
      }
    },
    dragleave (event) {
      event.currentTarget.classList.remove('base-drag-input--drag')
    },
    async drop (event) {
      event.preventDefault()
      this.$refs.uploadInput.files = event.dataTransfer.files

      const image = this.$refs.uploadInput.files[0]
      this.$emit('uploaded', image)
      // Clean up
      event.currentTarget.classList.remove('base-drag-input--drag')
    },
    openInputFile () {
      this.$refs.uploadInput.click()
    },
    onChangeInputFile (event) {
      const image = event.target.files[0]
      this.$emit('uploaded', image)
    }
    // upload (e) {
    //   const file = e.target.files[0]
    //   if (file) {
    //     this.$emit('uploaded', file)
    //   }
    // }
  }
}
</script>

<style scoped lang="scss">
.base-drag-input {
  background: #D9D9D9;
  border-radius: 15px;
  width: 521px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #4C4C4C;
  }

  &__subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #A2A2A2;
  }

  &__icon {
    max-height: 90px;
    max-width: 90px;
    margin-bottom: 24px;
  }

  &--drag {
    background: rgba(0, 0, 0, 0.25);
  }
}

.base-drag-input--audio .base-drag-input__icon {
  max-height: 90px;
  max-width: 90px;
  margin-bottom: 0;
}

</style>
