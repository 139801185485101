<template>
  <base-block @delete="$emit('delete')">
    <v-form ref="form">
      <div class="d-flex flex-column align-center mx-auto" style="width: 885px;">
        <base-text-area :value="block.content" class="full-width mb-2" label="Descripción de boton (Opcional)" @input="update('content', $event)" />
        <v-text-field :value="block.buttonUrl" :rules="[rules.required, rules.isLink]" class="link-input align-self-center" height="54px" outlined
                      placeholder="Copiar aqui el link"
                      @input="update('buttonUrl', $event)"
        />
        <div>
          <base-button color="#243674" width="336px">
            <v-text-field :value="block.buttonName" placeholder="Editar nombre de botón" dark text class="text-center" @input="update('buttonName', $event); errors.buttonName = ''" />
          </base-button>
          <div style="height: 24px;">
            <v-slide-y-transition>
              <span v-show="errors.buttonName" class="mx-3 mb-0 error--text" transition="fade">
                {{ errors.buttonName }}
              </span>
            </v-slide-y-transition>
          </div>
        </div>
      </div>
    </v-form>
  </base-block>
</template>

<script>
import BaseBlock from '@/components/material/blocks/BaseBlock'
import BaseButton from '@/components/buttons/BaseButton'
import BaseTextArea from '@/components/inputs/BaseTextArea'
import { isLink, required } from '@/utils/rules'

export default {
  name: 'ButtonBlock',
  components: {
    BaseBlock,
    BaseButton,
    BaseTextArea
  },
  props: ['block'],
  data () {
    return {
      rules: {
        required,
        isLink
      },
      errors: {
        buttonName: ''
      }
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.block, [key]: value })
    },
    validate () {
      let validate = true
      validate = this.$refs.form.validate()

      if (!this.block.buttonName) {
        validate = false
        this.errors.buttonName = 'Este campo es necesario'
      }
      return validate
    }
  }
}
</script>

<style scoped lang="scss">

.base-button ::v-deep input {
  text-align: center;
}

.link-input {
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 450px;
}

.link-input ::v-deep fieldset {
  color: #C8C8C8 !important;
}

.link-input ::v-deep input {
  border: 1px solid #C8C8C8;
  border-radius: 10px;
}

.error--text {
  font-size: 12px;
}

</style>
