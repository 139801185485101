<template>
  <v-card class="material-block pa-8">
    <v-btn class="material-block__close-btn" text color="#D04036" @click="$emit('delete')">
      Eliminar
    </v-btn>
    <div class="full-width d-flex flex-column">
      <slot />
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'BaseBlock',
  components: {},
  props: {
    type: String
  },
  data () {
    return {
      buttonName: '',
      previewFile: null,
      content: ''
    }
  },
  methods: {
    uploadFile (e) {
      const file = e.target.files[0]
      this.setPreviewImage(file)
    },
    loadFile () {
      this.$refs.pdfFile.click()
    },
    setPreviewImage (image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.previewFile = e.target.result
      }
    },
    removeFile () {
      this.previewFile = null
    }
  }

}
</script>

<style scoped lang="scss">
.material-block {
  border: 1px solid #E2E9E6;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__close-btn {
    letter-spacing: 0;
    text-transform: none;
    position: absolute;
    right: 25px;
    top: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }
}

.link-input {
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 450px;
}

.link-input ::v-deep fieldset {
  color: #C8C8C8 !important;
}

.link-input ::v-deep input {
  border: 1px solid #C8C8C8;
  border-radius: 10px;
}

.base-button ::v-deep .v-text-field input {
  text-align: center;
}

.download-pdf-button {
  background: #FFFFFF;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  text-transform: none;
  letter-spacing: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #4C4C4C;
}

.previewer ::v-deep canvas {
  margin: auto;
  //width: 215px!important;
  //height: 300px!important;
}

.preview-container {
  width: 296px;
  height: 186px;
  background-color: rgb(196, 196, 196);
  overflow: hidden;
  border: 1px solid rgb(192, 192, 192);
  border-radius: 10px;
  position: relative;
}

.remove-btn {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
