<template>
  <v-form ref="form">
    <div class="d-flex justify-space-between">
      <div class="d-flex full-width justify-space-between">
        <p class="subheader__title mb-8">
          {{ formTitle }}
        </p>

        <v-spacer />

        <div class="d-flex align-end">
          <base-menu
            :items="types"
            no-item-text="Type"
            label="Ícono"
            rounded="lg"
            :nudge-right="75"
            activator-width="108px"
            :error-message="errors.type || ''"
            @input="errors.type = ''"
          >
            <template #show-item>
              <v-img v-if="selectedType" :src="selectedType.icon" height="30px" width="30px" max-width="30px" />
              <template v-else>
                Type
              </template>
            </template>

            <template #content="{items}">
              <div class="d-flex align-center pa-3" style="background-color: white;">
                <template v-for="(item, index) in items">
                  <v-img :key="`img-${index}`" :src="item.icon" height="30px" width="30px" max-width="30px"
                         class="mx-2" style="cursor: pointer;" @click="selectType(item)"
                  />
                  <v-divider v-if="index !== items.length - 1" :key="`divider-${index}`" style="height: 20px;"
                             vertical
                             class="my-auto"
                  />
                </template>
              </div>
            </template>
          </base-menu>

          <base-menu
            v-model="level"
            return-object
            item-text="name"
            :items="levels"
            no-item-text="Levels"
            label="Nivel sugerido"
            :error-message="errors.level || ''"
            @input="errors.level = ''"
          />

          <base-text-field
            v-model="order"
            class="order-input"
            type="number"
            label="Orden"
            height="50px"
            block
            plain
            outlined
            :rules="[rules.orderRequired, rules.minValue]"
            :error-messages="errors.order || ''"
            @input="errors.order = ''"
          />

          <form-button secondary width="165px" class="mb-2 ml-2" @click="preview">
            Vista previa
          </form-button>
          <form-button width="165px" :loading="loading" class="mb-2" @click="save()">
            Guardar
          </form-button>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <p class="input-title">
        Titulo de material
      </p>
      <v-text-field v-model="name" :rules="[rules.required]" height="50px" block plain outlined
                    class="text-input"
      />

      <template v-for="(block, index) in blocks">
        <component
          :is="getBlockType(block)"
          :key="index"
          :ref="`form-${block.type}-${index + 1}`"
          :block="block"
          class="mb-13"
          :type="block.type"
          :is-editing="isEditing"
          @delete="removeBlock(index)"
          @input="updateBlock($event, index)"
        />
      </template>
      <block-input @blockTypeSelected="addNewBlock($event)" />
    </div>
  </v-form>
</template>

<script>
import BlockInput from '@/components/material/blocks/AddBlock'
import FormButton from '@/components/buttons/FormButton'
import BaseMenu from '@/components/inputs/BaseMenu'
import MaterialBlock from '@/components/material/blocks/BaseBlock'
import TextBlock from '@/components/material/blocks/TextBlock'
import ImageBlock from '@/components/material/blocks/ImageBlock'
import ButtonBlock from '@/components/material/blocks/ButtonBlock'
import FileBlock from '@/components/material/blocks/FileBlock'
import AudioBlock from '@/components/material/blocks/AudioBlock'
import VideoBlock from '@/components/material/blocks/VideoBlock'
import { required } from '@/utils/rules'
import { mapActions } from 'vuex'
import scheduleService from '@/services/scheduleService'
import BaseTextField from '@/components/inputs/BaseTextField'
import materialService from '@/services/materialService'

export default {
  name: 'AddMaterialForm',
  components: {
    FormButton,
    BlockInput,
    BaseMenu,
    MaterialBlock,
    TextBlock,
    ImageBlock,
    ButtonBlock,
    FileBlock,
    AudioBlock,
    VideoBlock,
    BaseTextField
  },
  props: ['material', 'loading', 'formTitle', 'isEditing'],
  data () {
    return {
      levels: [],
      rules: {
        required,
        minValue: v => v >= 0 || 'No permitido',
        orderRequired: v => v === 0 || !!v || 'No permitido'
      },
      errors: {
        type: '',
        level: '',
        order: ''
      },
      types: [
        {
          value: 'video',
          icon: '/img/materials/record-icon.svg'
        },
        {
          value: 'card',
          icon: '/img/materials/card-icon.svg'
        },
        {
          value: 'message',
          icon: '/img/materials/monitor-icon.svg'
        },
        {
          value: 'file',
          icon: '/img/materials/file-icon.svg'
        },
        {
          value: 'text',
          icon: '/img/materials/text-icon.svg'
        },
        {
          value: 'audio',
          icon: '/img/materials/audio-icon.svg'
        }
      ]
    }
  },
  computed: {
    // newMaterial: {
    //   get () { return this.$store.state.materials.newMaterial },
    //   set (value) { this.setNewMaterial(value) }
    // }
    previusRoute () {
      return this.prevRoute
    },
    type: {
      get () {
        return this.$store.state.materials.newMaterial.type
      }
    },
    level: {
      get () {
        return this.$store.state.materials.newMaterial.level
      },
      async set (value) {
        this.$store.commit('materials/SET_MATERIAL_FORM_IS_MODIFIED', true)
        await this.setNewMaterial({
          ...this.$store.state.materials.newMaterial,
          level: value
        })
      }
    },
    order: {
      get () {
        return this.$store.state.materials.newMaterial.order
      },
      async set (value) {
        this.$store.commit('materials/SET_MATERIAL_FORM_IS_MODIFIED', true)
        await this.setNewMaterial({
          ...this.$store.state.materials.newMaterial,
          order: Number(value)
        })
      }
    },
    name: {
      get () {
        return this.$store.state.materials.newMaterial.name
      },
      async set (value) {
        this.$store.commit('materials/SET_MATERIAL_FORM_IS_MODIFIED', true)
        await this.setNewMaterial({
          ...this.$store.state.materials.newMaterial,
          name: value
        })
      }
    },
    selectedType () {
      return this.types.find((type) => this.type === type.value)
    },
    blocks: {
      get () {
        return this.$store.state.materials.newMaterial.blocks
      }
    }
  },
  async mounted () {
    this.levels = await scheduleService.getAllLevels()
    if (this.previusRoute && this.previusRoute.name !== 'MaterialPreview') {
      this.$store.commit('materials/SET_MATERIAL_FORM_IS_MODIFIED', false)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    ...mapActions('materials', ['addNewMaterialBlock', 'removeMaterialBlock', 'replaceMaterialBlockByIndex', 'setNewMaterial']),
    addNewBlock (event) {
      this.$store.commit('materials/SET_MATERIAL_FORM_IS_MODIFIED', true)
      const newBlock = { type: event }
      if (newBlock.type === 'text') {
        newBlock.content = ''
      } else if (newBlock.type === 'image' || newBlock.type === 'video') {
        newBlock.file = ''
      } else if (newBlock.type === 'file') {
        newBlock.content = ''
        newBlock.file = ''
        newBlock.fileName = ''
      } else if (newBlock.type === 'audio') {
        newBlock.content = ''
        newBlock.file = ''
      } else if (newBlock.type === 'button') {
        newBlock.content = ''
        newBlock.buttonUrl = ''
        newBlock.buttonName = ''
      }
      this.addNewMaterialBlock(newBlock)
    },
    async removeBlock (blockIndex) {
      await this.removeMaterialBlock(blockIndex)
    },
    async selectType (type) {
      this.$store.commit('materials/SET_MATERIAL_FORM_IS_MODIFIED', true)
      this.errors.type = ''
      await this.setNewMaterial({
        ...this.$store.state.materials.newMaterial,
        type: type.value
      })
    },
    getBlockType (block) {
      return `${block.type}Block`
    },
    async validateForm () {
      let typeValidate = true
      if (!this.type) {
        this.errors.type = 'Requerido'
        typeValidate = false
      }

      let levelValidate = true
      if (!this.level) {
        this.errors.level = 'Requerido'
        levelValidate = false
      }

      // Validate new Material form
      let formValidate = true
      formValidate = this.$refs.form.validate()

      // Validate material order
      let materialOrderValidate = true
      if (this.order > 0) {
        const currentMaterialId = this.$route.params.id
        materialOrderValidate = await materialService.validateUniqueMaterialOrder(this.order, currentMaterialId)
        if (!materialOrderValidate) {
          this.errors.order = 'Orden ocupado'
        }
      }

      // Validate all blocks
      const forms = this.$refs
      let blocksValidate = true
      let firstFormError = null
      Object.values(forms).map((form) => {
        if (form[0] && !form[0].validate()) {
          if (!firstFormError) {
            firstFormError = form
            firstFormError[0].$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
          blocksValidate = false
        }
      })

      const validate = typeValidate && levelValidate && formValidate && materialOrderValidate && blocksValidate

      return validate
    },
    async updateBlock ($event, index) {
      this.$store.commit('materials/SET_MATERIAL_FORM_IS_MODIFIED', true)
      const payload = { block: $event, index }
      await this.replaceMaterialBlockByIndex(payload)
    },
    async save () {
      if (await this.validateForm()) {
        this.$emit('save', this.$store.state.materials.newMaterial)
        this.$store.commit('materials/SET_MATERIAL_FORM_IS_MODIFIED', false)
      }
    },
    async preview () {
      if (await this.validateForm()) {
        await this.$router.push({ name: 'MaterialPreview' })
      }
    }
  }
}
</script>

<style scoped lang="scss">

.input-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #4C4C4C;
  margin-bottom: 10px;
}

.text-input {
  border-radius: 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: #4C4C4C;
}

.text-input ::v-deep .v-input__slot {
  padding: 0 35px !important;
}

.text-input ::v-deep fieldset {
  color: #E2E9E6 !important;
}

.order-input {
  margin-bottom: -9px;
  width: 115px;
}

.order-input ::v-deep fieldset {
  color: #929292 !important;
  border: 1px solid;
}

.order-input ::v-deep .base-modal__label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  color: #C4C4C4;
  margin-bottom: 4px;
}

.order-input ::v-deep .v-text-field .v-text-field__details {
  margin-bottom: -2px;
  margin-top: 4px;
}

</style>
