<template>
  <base-block @delete="$emit('delete')">
    <v-form ref="form">
      <div style="width: 886px;" class="d-flex flex-column align-center mx-auto">
        <div class="mb-15">
          <div class="mb-0">
            <div class="preview-container">
              <template v-if="previewFile">
                <vue-pdf-embed class="previewer" :source="previewFile" :page="1" height="186"
                               width="296"
                />
                <v-btn
                  class="remove-btn ma-2"
                  fab
                  dark
                  height="35px"
                  width="35px"
                  color="error"
                  @click="removeFile"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
            </div>
            <div style="height: 24px;">
              <v-slide-y-transition>
                <span v-show="errors.file" class="mx-3 mb-0 error--text" transition="fade">
                  {{ errors.file }}
                </span>
              </v-slide-y-transition>
            </div>
          </div>
          <base-text-field :value="block.fileName" :rules="[rules.required]" placeholder="Bienvenido!"
                           label="Nombre de PDF" @input="update('fileName', $event)"
          />
          <v-btn color="#FFFFFF" class="download-pdf-button" height="73px" width="296px" @click="loadFile()">
            <div class="d-flex flex-column ">
              <v-icon color="#4C4C4C">
                mdi-upload
              </v-icon>
              Upload
            </div>
          </v-btn>
          <input v-show="false" id="input" ref="pdfFile" type="file" @change="uploadFile">
        </div>
        <base-text-area :value="block.content" class="full-width mb-4"
                        label="Descripción del PDF (Opcional)"
                        @input="update('content', $event)"
        />
      </div>
    </v-form>
  </base-block>
</template>

<script>
import BaseBlock from '@/components/material/blocks/BaseBlock'
import BaseTextArea from '@/components/inputs/BaseTextArea'
import BaseTextField from '@/components/inputs/BaseTextField'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { required } from '@/utils/rules'

export default {
  name: 'FileBlock',
  components: {
    BaseBlock,
    BaseTextArea,
    BaseTextField,
    VuePdfEmbed
  },
  props: ['block'],
  data () {
    return {
      previewFile: this.block.fileUrl,
      rules: {
        required
      },
      errors: {
        file: ''
      }
    }
  },
  computed: {
    localFile () {
      return this.block.file
    }
  },
  watch: {
    localFile (newValue) {
      if (newValue) {
        this.setPreviewFile(newValue)
      } else {
        this.previewFile = null
      }
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.block, [key]: value })
    },
    uploadFile (e) {
      const file = e.target.files[0]
      this.errors.file = ''
      this.update('file', file)
    },
    loadFile () {
      this.$refs.pdfFile.click()
    },
    setPreviewFile (image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.previewFile = e.target.result
      }
    },
    removeFile () {
      this.$emit('input', { ...this.block, file: null, fileUrl: null })
    },
    validate () {
      let validate = true
      validate = this.$refs.form.validate()

      if (!this.block.file && !this.block.fileUrl) {
        validate = false
        this.errors.file = 'Este campo es necesario'
      }
      return validate
    }
  }
}
</script>

<style scoped lang="scss">
.previewer ::v-deep canvas {
  margin: auto;
  //width: 215px!important;
  //height: 300px!important;
}

.preview-container {
  width: 296px;
  height: 186px;
  background-color: rgb(196, 196, 196);
  overflow: hidden;
  border: 1px solid rgb(192, 192, 192);
  border-radius: 10px;
  position: relative;
}

.download-pdf-button {
  background: #FFFFFF;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  text-transform: none;
  letter-spacing: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #4C4C4C;
}

.remove-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.error--text {
  font-size: 12px;
}

</style>
