<template>
  <base-block @delete="$emit('delete')">
    <div class="d-flex flex-column align-center mx-auto" style="width: 885px;">
      <base-text-area :value="block.content" hide-details class="full-width mb-4" label="Descripción de Audio (Opcional)" @input="update('content', $event)" />
      <div>
        <drag-audio-input :file="block.file" :preview-url="block.fileUrl" :autoplay="!isEditing || !!block.file" type="audio" @loadedAudio="update('file', $event); errorMessage=''" @clearAudio="clearFile" />
        <v-slide-y-transition>
          <span v-show="errorMessage" class="mx-3 mb-0 error--text" transition="fade">
            {{ errorMessage }}
          </span>
        </v-slide-y-transition>
      </div>
    </div>
  </base-block>
</template>

<script>
import BaseBlock from '@/components/material/blocks/BaseBlock'
import BaseTextArea from '@/components/inputs/BaseTextArea'
import DragAudioInput from '@/components/inputs/DragAudioInput'

export default {
  name: 'AudioBlock',
  components: {
    BaseBlock,
    BaseTextArea,
    DragAudioInput
  },
  props: ['block', 'isEditing'],
  data () {
    return {
      errorMessage: ''
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.block, [key]: value })
    },
    clearFile () {
      this.$emit('input', { ...this.block, file: null, fileUrl: null })
    },
    validate () {
      let validate = true
      if (!this.block.file && !this.block.fileUrl) {
        validate = false
        this.errorMessage = 'Este campo es necesario'
      }
      return validate
    }
  }
}
</script>

<style scoped lang="scss">
.error--text {
  font-size: 12px;
}

</style>
